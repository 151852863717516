// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/imageservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ExifMetadata, ExternalImageId, Image, ImageLocation, ImageMetadata } from "./image_pb.js";
import { BoundingBox } from "./geometry_pb.js";
import { Property } from "./feature_pb.js";
import { Tag } from "./tag_pb.js";
import { AnnotatedImage, CorrectedImageAnnotation, ImageAnnotationLabel, Rectangle } from "./image_annotation_pb.js";

/**
 * @generated from message tapestry.gridaware.api.v1.GetImagesRequest
 */
export const GetImagesRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetImagesRequest", () => [{
  no: 1,
  name: "ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetImagesResponse
 */
export const GetImagesResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetImagesResponse", () => [{
  no: 1,
  name: "images",
  kind: "message",
  T: Image,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.QueryImagesRequest
 */
export const QueryImagesRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.QueryImagesRequest", () => [{
  no: 1,
  name: "query",
  kind: "message",
  T: ImageQuery
}, {
  no: 2,
  name: "max_results",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "include_related_assets",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);

/**
 * A list of parameters that must be met in order for an image to be
 * returned. Images will be returned if they meet all of the specified
 * parameters.
 *
 * @generated from message tapestry.gridaware.api.v1.ImageQuery
 */
export const ImageQuery = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageQuery", () => [{
  no: 1,
  name: "asset_ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "feature_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "bounds",
  kind: "message",
  T: BoundingBox
}, {
  no: 3,
  name: "tag_names",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "properties",
  kind: "message",
  T: Property,
  repeated: true
}, {
  no: 6,
  name: "include_inactive",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.QueryImagesResponse
 */
export const QueryImagesResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.QueryImagesResponse", () => [{
  no: 1,
  name: "images",
  kind: "message",
  T: Image,
  repeated: true
}]);

/**
 * Next ID: 8
 *
 * @generated from message tapestry.gridaware.api.v1.AddGCSImageRequest
 */
export const AddGCSImageRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.AddGCSImageRequest", () => [{
  no: 1,
  name: "gcs_uri",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "original_file_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "image_location",
  kind: "message",
  T: ImageLocation
}, {
  no: 6,
  name: "tags",
  kind: "message",
  T: Tag,
  repeated: true
}, {
  no: 7,
  name: "captured_at",
  kind: "message",
  T: Timestamp
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.AddGCSImageResponse
 */
export const AddGCSImageResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.AddGCSImageResponse", () => [{
  no: 1,
  name: "image",
  kind: "message",
  T: Image
}]);

/**
 * Next ID: 10
 *
 * @generated from message tapestry.gridaware.api.v1.AddImageRequest
 */
export const AddImageRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.AddImageRequest", () => [{
  no: 1,
  name: "blob_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  oneof: "data_source"
}, {
  no: 3,
  name: "bytes",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */,
  oneof: "data_source"
}, {
  no: 8,
  name: "external_image_id",
  kind: "message",
  T: ExternalImageId,
  oneof: "data_source"
}, {
  no: 2,
  name: "original_file_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "image_metadata",
  kind: "message",
  T: ImageMetadata
}, {
  no: 5,
  name: "prioritize_exif_metadata_location",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 6,
  name: "require_image_location",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 7,
  name: "save_exif_metadata_orientation",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 9,
  name: "exif_metadata",
  kind: "message",
  T: ExifMetadata
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.AddImageResponse
 */
export const AddImageResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.AddImageResponse", () => [{
  no: 1,
  name: "image",
  kind: "message",
  T: Image
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateImageRequest
 */
export const UpdateImageRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateImageRequest", () => [{
  no: 1,
  name: "image",
  kind: "message",
  T: Image
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateImageResponse
 */
export const UpdateImageResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateImageResponse", []);

/**
 * @generated from message tapestry.gridaware.api.v1.ReadImageContentRequest
 */
export const ReadImageContentRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.ReadImageContentRequest", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "max_width",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "max_height",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);

/**
 * A portion of the content of an image.
 * This message has been renamed from ImageChunk -> ReadImageContentResponse
 *
 * @generated from message tapestry.gridaware.api.v1.ReadImageContentResponse
 */
export const ReadImageContentResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.ReadImageContentResponse", () => [{
  no: 1,
  name: "content",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}, {
  no: 2,
  name: "content_type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "bytes_remaining",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetImageAnnotationsRequest
 */
export const GetImageAnnotationsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetImageAnnotationsRequest", () => [{
  no: 1,
  name: "image_ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetImageAnnotationsResponse
 */
export const GetImageAnnotationsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetImageAnnotationsResponse", () => [{
  no: 1,
  name: "annotated_images",
  kind: "message",
  T: AnnotatedImage,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.SaveImageAnnotationsRequest
 */
export const SaveImageAnnotationsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.SaveImageAnnotationsRequest", () => [{
  no: 1,
  name: "annotated_image",
  kind: "message",
  T: AnnotatedImage
}, {
  no: 2,
  name: "updated_indices",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.SaveImageAnnotationsResponse
 */
export const SaveImageAnnotationsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.SaveImageAnnotationsResponse", () => [{
  no: 1,
  name: "annotated_image",
  kind: "message",
  T: AnnotatedImage
}]);

/**
 * ImageAnnotationKey has fields related to an image annotation that can be
 * used to construct a unique identifier for an image annotation. This can be
 * deprecated when image annotations have their own ID field (b/320485418).
 *
 * @generated from message tapestry.gridaware.api.v1.ImageAnnotationKey
 */
export const ImageAnnotationKey = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageAnnotationKey", () => [{
  no: 1,
  name: "image_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "label",
  kind: "enum",
  T: proto3.getEnumType(ImageAnnotationLabel)
}, {
  no: 3,
  name: "rectangle",
  kind: "message",
  T: Rectangle,
  oneof: "shape"
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.CorrectImageAnnotationRequest
 */
export const CorrectImageAnnotationRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.CorrectImageAnnotationRequest", () => [{
  no: 1,
  name: "key",
  kind: "message",
  T: ImageAnnotationKey
}, {
  no: 2,
  name: "label",
  kind: "enum",
  T: proto3.getEnumType(ImageAnnotationLabel)
}, {
  no: 3,
  name: "comment",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.CorrectImageAnnotationResponse
 */
export const CorrectImageAnnotationResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.CorrectImageAnnotationResponse", () => [{
  no: 1,
  name: "corrected",
  kind: "message",
  T: CorrectedImageAnnotation
}]);