import {Observable, Subject} from 'rxjs';
import {filter, first, map, switchMap, takeUntil, tap} from 'rxjs/operators';

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {CanDeactivateComponent} from '../core/navigation/can_deactivate_guard';
import {AnnotationsService} from '../services/annotations_service';
import {ConfigService} from '../services/config_service';
import {GalleryService} from '../services/gallery_service';
import {KeyboardService} from '../services/keyboard_service';
import {Annotation, AnnotationEditorMode, PendingAnnotatedImage} from '../typings/annotations';
import {
  buildImageUrlWithOptions,
  defaultImageUrlOptions,
  getNewImageIndex,
  prefetchImages,
} from '../utils/image';

const TOAST_DURATION_MS = 2500;

/**
 * Image annotation viewer and editor.
 */
@Component({
  selector: 'image-studio',
  templateUrl: './image_studio.ng.html',
  styleUrls: ['./image_studio.scss'],
})
export class ImageStudio implements OnInit, OnDestroy, CanDeactivateComponent {
  // Total number of images comprising a group.
  @Input() totalImageCount = 0;

  // Index of the currently displayed image in the group.
  @Input() selectedImageIndex = 0;

  // Sets URL options for the image.
  @Input() imageUrlOptions = {...defaultImageUrlOptions};

  // Callback on previous image navigation.
  @Output() readonly onPrevious = new EventEmitter<void>();

  // Callback on next image navigation.
  @Output() readonly onNext = new EventEmitter<void>();

  @ViewChild('container', {static: true}) container!: ElementRef;

  // Used to expose the AnnotationEditorMode enum for use in the template.
  EDITOR_MODE = AnnotationEditorMode;

  // Indicates the editor mode image studio should render with.
  editorMode = AnnotationEditorMode.OFF;

  // Indicates whether the image is being loaded at the moment.
  loading = false;

  // URL of an image to be annotated.
  imageUrl = '';

  // ID of an image to be annotated.
  imageId = '';

  // Direction from which the image was taken (0-360).
  bearing = 0;

  // Images from galleryService.
  images: Image[] = [];

  // Selected image being viewed in the studio.
  selectedImage: Image | null = null;

  // Annotations already existing on the image.
  existingAnnotations: Annotation[] = [];

  // The initial annotations on the imate
  initialAnnotations: Annotation[] = [];

  // Used as a tracking element for annotations drawing (namely, this is the
  // element that we will track pointer release on).
  containerEl: HTMLElement | null = null;

  /**
   * If `carouselEnabled` is true then this flag changes whenever a user
   * presses the button to show or hide the carousel.
   */
  protected showCarousel = false;

  /**
   * The carousel is only enabled if feature flag enabled and if there's
   * at least two images.
   */
  protected carouselEnabled = false;

  /**
   * The asset timeline is enabled only if feature flag is enabled and layer corresponding to the
   * feature opened in the studio supports asset timeline.
   */
  protected assetTimelineEnabled = false;

  rePositionLabels = new Subject<void>();

  private readonly destroyed = new Subject<void>();

  private readonly resizeObserver = new ResizeObserver(() => {
    this.rePositionLabels.next();
  });

  constructor(
    private readonly annotationsService: AnnotationsService,
    private readonly configService: ConfigService,
    private readonly galleryService: GalleryService,
    private readonly snackBar: MatSnackBar,
    private readonly keyboardService: KeyboardService,
  ) {}

  ngOnInit() {
    this.galleryService.images.pipe(takeUntil(this.destroyed)).subscribe((images) => {
      this.images = images;
      this.carouselEnabled = this.configService.studioCarouselEnabled && this.images.length > 1;
      this.assetTimelineEnabled =
        this.configService.assetTimelineEnabled &&
        this.galleryService.checkLayerSupportsAssetTimeline();
      // If URL has 'hideCarousel' then initially hide carousel.
      const hideCarousel = this.galleryService.hideCarousel === 'true';
      this.showCarousel = !hideCarousel && this.carouselEnabled;
      // Update the selected image index if image exists, otherwise use 0.
      const imageId = this.galleryService.imageId;
      if (imageId && this.images.length > 1) {
        this.selectedImageIndex = this.images.findIndex((image) => image.id === imageId);
        if (this.selectedImageIndex === -1) {
          this.selectedImageIndex = 0;
          this.snackBar.open(`Could not load imageId ${imageId}.`, '', {
            duration: TOAST_DURATION_MS,
          });
        }
      }
      prefetchImages(images, this.imageUrlOptions, this.destroyed);
    });
    this.galleryService.selectedImage
      .pipe(
        filter((image: Image | null) => image !== null),
        map((image: Image | null): Image => image!),
        tap((image: Image) => {
          this.existingAnnotations = [];
          this.imageId = image.id;
          buildImageUrlWithOptions(image, this.imageUrlOptions)
            .pipe(takeUntil(this.destroyed))
            .subscribe({
              next: (url) => {
                this.imageUrl = url;
              },
              error: (error) => {
                throw new Error(error);
              },
            });
          this.bearing = image.location?.bearing || 0;
          this.loading = true;
          this.selectedImage = image;
        }),
        switchMap(
          (image: Image): Observable<[Image, PendingAnnotatedImage]> =>
            this.annotationsService.getPendingAnnotations(image.id).pipe(
              map((annotationsState: PendingAnnotatedImage): [Image, PendingAnnotatedImage] => [
                image,
                annotationsState,
              ]),
              takeUntil(this.destroyed),
            ),
        ),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: ([, annotationsState]) => {
          this.loading = false;
          this.existingAnnotations = annotationsState.annotations;
        },
        error: () => {
          this.loading = false;
          this.showErrorNotification();
          this.snackBar.open('Could not load image data.', '', {
            duration: TOAST_DURATION_MS,
          });
        },
      });
    this.annotationsService.annotationsStateChanged
      .pipe(first())
      .subscribe((annotations: Annotation[]) => {
        this.initialAnnotations = annotations;
      });
    this.keyboardService.keydown
      .pipe(takeUntil(this.destroyed))
      .subscribe(this.onKeyDown.bind(this));
    this.galleryService
      .getEditorMode()
      .pipe(takeUntil(this.destroyed))
      .subscribe((mode: AnnotationEditorMode) => {
        this.editorMode = mode;
      });
    this.containerEl = (this.container?.nativeElement as HTMLElement) || null;
    if (this.containerEl) {
      this.resizeObserver.observe(this.containerEl);
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.annotationsUpdated(this.initialAnnotations, this.existingAnnotations);
  }

  annotationsUpdated(initialAnnotations: Annotation[], currentAnnotations: Annotation[]) {
    if (initialAnnotations.length !== currentAnnotations.length) {
      return false;
    }
    for (const [index, initialAnnotation] of initialAnnotations.entries()) {
      const currentAnnotation = currentAnnotations[index];
      // Because of the issues with Message.equals in jspb, just compare the createdAt timestamps as a natural "key",
      // as suggested here: go / jspb - api - gotchas#workarounds -for-problems -with-messageequals
      if (initialAnnotation.info.createdAt?.nanos !== currentAnnotation.info.createdAt?.nanos) {
        return false;
      }
    }
    return true;
  }

  ngOnDestroy() {
    if (this.containerEl) {
      this.resizeObserver.unobserve(this.containerEl);
    }
    this.destroyed.next();
    this.destroyed.complete();
  }

  onPreviousPressed() {
    this.onPrevious.emit();
    const index = getNewImageIndex(this.selectedImageIndex, this.totalImageCount, 'prev');
    if (index >= 0) {
      this.selectedImageIndex = index;
    }
  }

  onNextPressed() {
    this.onNext.emit();
    const index = getNewImageIndex(this.selectedImageIndex, this.totalImageCount, 'next');
    if (index >= 0) {
      this.selectedImageIndex = index;
    }
  }

  onHideCarousel(hideCarousel: boolean) {
    this.showCarousel = hideCarousel;
  }

  onKeyDown(event: KeyboardEvent) {
    if (!this.carouselEnabled) {
      return;
    }
    if (event.key === 'ArrowLeft') {
      this.onPreviousPressed();
    } else if (event.key === 'ArrowRight') {
      this.onNextPressed();
    } else if (event.key === 'ArrowUp') {
      this.showCarousel = true;
    } else if (event.key === 'ArrowDown') {
      this.showCarousel = false;
    }
  }

  private showErrorNotification() {
    this.snackBar.open('Failed to load image data.', '', {
      duration: TOAST_DURATION_MS,
    });
  }
}
