// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/image_annotation.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EnumValueOptions, proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";

/**
 * Possible labels for an image annotation.
 * Next ID: 51
 *
 * @generated from enum tapestry.gridaware.api.v1.ImageAnnotationLabel
 */
export const ImageAnnotationLabel = proto3.makeEnum("tapestry.gridaware.api.v1.ImageAnnotationLabel", [{
  no: 0,
  name: "UNKNOWN_LABEL"
}, {
  no: 1,
  name: "TRANSFORMER_LABEL"
}, {
  no: 18,
  name: "ISOLATOR_LABEL"
}, {
  no: 2,
  name: "SWITCH_LABEL"
}, {
  no: 3,
  name: "POLE_LABEL"
}, {
  no: 9,
  name: "AERIAL_SPACER_CABLE_LABEL"
}, {
  no: 10,
  name: "BARE_PRIMARY_WIRE_LABEL"
}, {
  no: 12,
  name: "CROSSARM_LABEL"
}, {
  no: 13,
  name: "CUT_OUT_LABEL"
}, {
  no: 14,
  name: "DEAD_END_TYPE_INSULATOR_LABEL"
}, {
  no: 16,
  name: "DISCONNECT_SWITCH_GANG_LABEL"
}, {
  no: 17,
  name: "DOWN_GUY_LABEL"
}, {
  no: 19,
  name: "LIGHTING_ARRESTOR_LABEL"
}, {
  no: 20,
  name: "LINE_SENSOR_LABEL"
}, {
  no: 22,
  name: "PIN_TYPE_INSULATOR_LABEL"
}, {
  no: 23,
  name: "PRIMARY_METER_LABEL"
}, {
  no: 24,
  name: "PRIMARY_RISER_LABEL"
}, {
  no: 25,
  name: "RECLOSER_LABEL"
}, {
  no: 26,
  name: "REGULATOR_LABEL"
}, {
  no: 28,
  name: "SECONDARY_OPEN_WIRE_LABEL"
}, {
  no: 29,
  name: "SECTIONALIZER_LABEL"
}, {
  no: 30,
  name: "STREETLIGHT_LABEL"
}, {
  no: 31,
  name: "TREE_WIRE_LABEL"
}, {
  no: 32,
  name: "CAPACITOR_LABEL"
}, {
  no: 37,
  name: "RECLOSER_OIL_CIRCUIT_LABEL"
}, {
  no: 38,
  name: "RECLOSER_VACUUM_CIRCUIT_LABEL"
}, {
  no: 39,
  name: "RECLOSER_AUTOMATIC_TRANSFER_LABEL"
}, {
  no: 40,
  name: "SECONDARY_POWER_SOURCE_LABEL"
}, {
  no: 41,
  name: "CONTROL_BOX_LABEL"
}, {
  no: 42,
  name: "CUT_OUT_POLYMER_LABEL"
}, {
  no: 43,
  name: "CUT_OUT_PORCELAIN_LABEL"
}, {
  no: 44,
  name: "TREE_LABEL"
}, {
  no: 45,
  name: "COMMUNICATION_POLE_LABEL"
}, {
  no: 48,
  name: "GROUND_TRANSFORMER_LABEL"
}, {
  no: 49,
  name: "GROUND_LV_CABINET"
}, {
  no: 50,
  name: "GROUND_ASSET_LABEL"
}, {
  no: 47,
  name: "SWITCH_OPEN_LABEL"
}, {
  no: 46,
  name: "OTHER_LABEL"
}, {
  no: 6,
  name: "VEGETATION_OVERGROWTH_LABEL"
}, {
  no: 33,
  name: "PIN_INSULATOR_DEFECT_LABEL"
}, {
  no: 34,
  name: "CROSSARM_DEFECT_LABEL"
}, {
  no: 35,
  name: "POLE_DEFECT_LABEL"
}, {
  no: 36,
  name: "CROSSARM_WITH_BRACE_DEFECT_LABEL"
}, {
  no: 4,
  name: "CAPACITOR_BANK_LABEL"
}, {
  no: 5,
  name: "SUPPORT_STRUCTURE_LABEL"
}, {
  no: 11,
  name: "BOOSTER_LABEL"
}, {
  no: 7,
  name: "ASSET_DEFECT_LABEL"
}, {
  no: 15,
  name: "DISCONNECT_SWITCH_LABEL"
}, {
  no: 21,
  name: "NEUTRAL_BRACKET_LABEL"
}, {
  no: 27,
  name: "SECONDARY_MULTIPLEX_LABEL"
}]);

/**
 * Image annotations are associated with the image that they were created on.
 *
 * @generated from message tapestry.gridaware.api.v1.AnnotatedImage
 */
export const AnnotatedImage = proto3.makeMessageType("tapestry.gridaware.api.v1.AnnotatedImage", () => [{
  no: 1,
  name: "image_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "annotations",
  kind: "message",
  T: ImageAnnotation,
  repeated: true
}]);

/**
 * An image annotation represents an SVG shape (e.g. Rectangle) that highlights
 * a specific area of an image. It is linked to a set of predefined labels which
 * identify the contents of the annotation. It can also have a free form comment
 * associated to support sharing information between GA users, e.g. "This
 * transformer is rusty. We should replace it."
 * Next ID: 12
 *
 * @generated from message tapestry.gridaware.api.v1.ImageAnnotation
 */
export const ImageAnnotation = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageAnnotation", () => [{
  no: 11,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 1,
  name: "rectangle",
  kind: "message",
  T: Rectangle,
  oneof: "shape"
}, {
  no: 2,
  name: "labels",
  kind: "enum",
  T: proto3.getEnumType(ImageAnnotationLabel),
  repeated: true
}, {
  no: 3,
  name: "comment",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "user",
  kind: "message",
  T: User
}, {
  no: 5,
  name: "created_at",
  kind: "message",
  T: Timestamp
}, {
  no: 6,
  name: "updated_at",
  kind: "message",
  T: Timestamp
}, {
  no: 7,
  name: "is_machine_generated",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 8,
  name: "machine_confidence_score",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 9,
  name: "machine_model_version",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "corrected",
  kind: "message",
  T: CorrectedImageAnnotation
}]);

/**
 * The entity type of a label.
 *
 * @generated from enum tapestry.gridaware.api.v1.ImageAnnotation.LabelType
 */
export const ImageAnnotation_LabelType = proto3.makeEnum("tapestry.gridaware.api.v1.ImageAnnotation.LabelType", [{
  no: 0,
  name: "TYPE_UNSPECIFIED"
}, {
  no: 1,
  name: "ASSET"
}, {
  no: 2,
  name: "DEFECT"
}]);

/**
 * The canonical name of this label, notably for interoperability with ML
 * pipelines, but could be valuable for display as well.
 *
 * Within a particular enum, all the label_name options must be distinct.
 * (Concretely, we require a partial bijection between values of the enum
 * and label names.)
 *
 * @generated from extension: optional string label_name = 521579051;
 */
export const ImageAnnotation_label_name = proto3.makeExtension("tapestry.gridaware.api.v1.ImageAnnotation.label_name", EnumValueOptions, {
  no: 521579051,
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
});

/**
 * The entity type of the label.
 *
 * @generated from extension: optional tapestry.gridaware.api.v1.ImageAnnotation.LabelType label_type = 535801104;
 */
export const ImageAnnotation_label_type = proto3.makeExtension("tapestry.gridaware.api.v1.ImageAnnotation.label_type", EnumValueOptions, () => ({
  no: 535801104,
  kind: "enum",
  T: proto3.getEnumType(ImageAnnotation_LabelType),
  opt: true
}));

/**
 * CorrectedImageAnnotation captures information about what the correct
 * ML-generated annotation should be as well as metadata about the correction.
 *
 * @generated from message tapestry.gridaware.api.v1.CorrectedImageAnnotation
 */
export const CorrectedImageAnnotation = proto3.makeMessageType("tapestry.gridaware.api.v1.CorrectedImageAnnotation", () => [{
  no: 1,
  name: "label",
  kind: "enum",
  T: proto3.getEnumType(ImageAnnotationLabel)
}, {
  no: 2,
  name: "comment",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "user",
  kind: "message",
  T: User
}, {
  no: 4,
  name: "created_at",
  kind: "message",
  T: Timestamp
}]);

/**
 * Defines the x and y coordinates of a point on a 2D image canvas relative to
 * the origin at the top left of the canvas.
 *
 * Note. This message has been renamed from Point to ImageAnnotationPoint
 * during migrating to GCP to resolve a conflict with the Point in the
 * geometry.proto
 *
 * @generated from message tapestry.gridaware.api.v1.ImageAnnotationPoint
 */
export const ImageAnnotationPoint = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageAnnotationPoint", () => [{
  no: 1,
  name: "x",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */
}, {
  no: 2,
  name: "y",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */
}]);

/**
 * Defines the top left position, width and height of a rectangle.
 *
 * This stores coordinates relative to an SVG canvas with the same aspect
 * ratio as the annotated image, whose short side is 700 units long. Put
 * another way, image pixel coordinates are scaled by the factor
 *
 *     min(image.width, image.height) / 700.0
 *
 * TODO(b/282259087): Store normalized coordinates instead (x coordinates and
 * width values are fractions of the image width; y coordinates and height
 * values are fractions of the image height).
 *
 * @generated from message tapestry.gridaware.api.v1.Rectangle
 */
export const Rectangle = proto3.makeMessageType("tapestry.gridaware.api.v1.Rectangle", () => [{
  no: 1,
  name: "top_left",
  kind: "message",
  T: ImageAnnotationPoint
}, {
  no: 2,
  name: "width",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */
}, {
  no: 3,
  name: "height",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */
}]);