<div
  #container
  main
  class="container"
  [ngClass]="{'highlighted-container': editorMode !== EDITOR_MODE.OFF}">
  <div class="content-container">
    <image-canvas
      [imageUrl]="imageUrl"
      [imageId]="imageId"
      [bearing]="bearing"
      [showCarousel]="carouselEnabled && showCarousel"
      [showNavActions]="images.length > 1"
      [carouselEnabled]="carouselEnabled"
      [totalImageCount]="totalImageCount"
      [selectedImageIndex]="selectedImageIndex"
      [existingAnnotations]="existingAnnotations"
      [releaseTrackingElement]="containerEl"
      [rePositionLabels]="rePositionLabels"
      [editorMode]="editorMode"
      (onHideCarousel)="onHideCarousel($event)"
      (onPrevious)="onPreviousPressed()"
      (onNext)="onNextPressed()">
    </image-canvas>
    @if (editorMode === EDITOR_MODE.OFF && carouselEnabled) {
      <image-carousel
        [ngClass]="{
          'hidden-carousel': !showCarousel,
          'asset-timeline-enabled': assetTimelineEnabled
        }"
        [images]="images"
        [selectedImageIndex]="selectedImageIndex"
        (changedImageIndex)="selectedImageIndex = $event">
      </image-carousel>
    }
  </div>
</div>
