import {Timestamp} from '@bufbuild/protobuf';

import {
  ImageAnnotation,
  ImageAnnotationLabel,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';
import {User} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/user_pb';

/**
 * Includes both the actual enum value and display name for annotation label.
 */
export declare interface LabelInfo {
  value: ImageAnnotationLabel;
  label: string;
  type: AnnotationType;
}

/**
 * Shape and annotations info.
 */
export declare interface Annotation {
  info: AnnotationInfo;
  shapeType: AnnotationShape;
  shapeData: Rectangle;
  isUpdated?: boolean;
  isHidden?: boolean;
  // Setting used for newly added annotations, so that even though if the parent
  // selector is unchecked, they'd still be visible unless user explicitly
  // un-checks the newly added annotation from selector tree.  Used to control
  // the visibility of the annotations that were added, but not saved in the
  // editing mode.
  isExplicitlyVisible?: boolean;
}

/**
 * Full annotation info.
 */
export declare interface AnnotationInfo {
  label: LabelInfo | null;
  comment: string;
  user?: User;
  updatedAt: Timestamp | undefined;
  createdAt: Timestamp | undefined;
  isMachineGenerated: boolean;
  machineModelVersion: string;
  machineConfidenceScore: number;
  sourceAnnotation: ImageAnnotation;
}

/**
 * There are different text strings (e.g. Label, Modified Date, etc)
 * by which annotations can be filtered.
 */
export declare interface AnnotationFilterInfo {
  type: string;
  activated?: boolean; // If not specified, assumed to be deactivated.
  included?: boolean; // If true then include this type, o/w exclude.
}

/**
 * Pending state of an annotated image.
 */
export declare interface PendingAnnotatedImage {
  id: string;
  annotations: Annotation[];
  updatedImadeURL: string;
}

/**
 * Rectangular shape description.
 */
export declare interface Rectangle {
  top: number;
  left: number;
  width: number;
  height: number;
}

/**
 * The shape that can be used for annotating parts of the image.
 */
export enum AnnotationShape {
  NONE,
  RECTANGLE,
  ARROW,
}

/**
 * Each label type has a corresponding text value.
 */
export enum AnnotationFilterType {
  AUTHOR = 'Author',
  CREATED_DATE = 'Created Date',
  LABEL = 'Label',
  MODIFIED_DATE = 'Modified Date',
  SOURCE = 'Source',
}

/**
 * Image groups can be filtered based on one of these mutually
 * exclusive options.
 */
export enum AnnotationFilterOption {
  /** By default, return all image groups. */
  ALL_IMAGE_GROUPS = 'All image groups',
  /** Only return image groups where all images contain no annotations. */
  NO_ANNOTATIONS = 'No annotations',
  /** Only return image groups that have images with 1 or more annotations. */
  ONE_OR_MORE_ANNOTATIONS = '1 or more annotations',
}

/**
 * Annotations differ by the type of the objects they identify.
 */
export enum AnnotationType {
  ASSET,
  DEFECT,
}

/**
 * The mode in which annotation editor can be rendered.
 */
export enum AnnotationEditorMode {
  OFF,
  PAN,
  DRAW,
}
